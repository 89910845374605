export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore 404 - Pagina non trovata"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il contenuto che state cercando non è stato trovato"])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Coin App"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo wallet"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del wallet"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password del wallet"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un wallet"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa un wallet"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo wallet sarà salvato localmente nel tuo browser. La password del wallet è necessaria per accedervi e per firmare le transazioni."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si raccomanda di usare una password di almeno 12 caratteri, usando lettere minuscole, maiuscole, numeri e caratteri speciali"])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri il wallet"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il wallet"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password del wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robustezza"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apri il wallet"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic usage"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Tether  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet source code"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indietro"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna alla Home"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copia indirizzo"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copiato negli appunti"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop camera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambia navigazione"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra i miei account"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creat NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])}
  },
  "votemenu": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "my_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My questions"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "delegation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegation"])},
    "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "trusted_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list"])},
    "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governance"])},
    "ams01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Coin"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "voteask": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will show up in the table list of questions"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the question"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address with more question details (start with https://)"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add here url address where people can find more information about the question"])},
    "max_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of blocks since now until the question will be closed"])},
    "calculated_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max block"])},
    "calculated_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "response_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the response option"])},
    "remove_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove option"])},
    "responses_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses."])},
    "add_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new response"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge based category vote delegation in effect"])},
    "submit_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "encryptedVotingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow members to use encrypted voting"])},
    "encryptionAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption address - Generate new mnemonics for each voting and store it securly"])},
    "generateNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new encryption mnemonics"])}
  },
  "votedelegate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting power delegation"])},
    "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can delegate your voting power to someone you trust. It may be your friend or some public person."])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision."])},
    "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power."])},
    "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined."])},
    "category_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any category"])},
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
    "wallet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
    "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account to delegation"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegate specific category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify your delegation in knowledge based category.. eg IT, finance, governance"])},
    "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add specific category"])},
    "submit_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the button below, you will store your delegation preferences to the blockchain."])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store delegation to the blockchain for ", _interpolate(_named("accountName"))])}
  },
  "voteoverview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
  },
  "votequestions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])}
  },
  "votequestionlist": {
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "no_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be the first one to ask a question"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question text"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed at round"])},
    "maxround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open until round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List all questions"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question id"])},
    "current_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current round"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of the round"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "voting_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting has been closed"])},
    "latest_response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Latest vote for the account ", _interpolate(_named("accountName")), " is selected"])},
    "vote_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cast your vote"])},
    "sum_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted votes"])},
    "sum_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted coins"])},
    "trusted_list_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list result"])},
    "hypercapitalism_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 coin 1 vote results"])},
    "vote_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point."])},
    "check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check results"])},
    "sbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple balance results"])},
    "sbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "qbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic balance results"])},
    "qbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "ssar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple single account results"])},
    "ssar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic single account results"])},
    "qsar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "stlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple trusted list results"])},
    "stlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qtlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic trusted list results"])},
    "qtlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "encryptVoteCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypt my voting decision"])},
    "submit_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit my results for audit purposes"])},
    "mnemonics_required_to_get_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted voting requires mnemonics to properly calculate the results"])}
  },
  "votetl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list management"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add accounts to trusted list - one account per line"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove accounts from trusted list - one account per line"])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store trusted list to blockchain for ", _interpolate(_named("accountName"))])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added in the round n."])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted account"])},
    "no_tl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no trusted accounts listed for the account ", _interpolate(_named("accountName")), "."])}
  },
  "voteanswerslist": {
    "no_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no response yet to the question"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response json"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the algorand address"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network fee"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using QR code"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using algorand wallet"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using web wallet"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to merchant"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the payment"])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento su un indirizzo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni sulla posizione dei decimali per il QR Code"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta suggerita per l'indirizzo ricevente"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le note sono modificabili dall'utente"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima Account"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo pagamento"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricevi pagamento"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo senza le ricompense in attesa"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompense"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompense in attesa"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa base"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps local state"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apps total schema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assets"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicazioni create"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglia per la firma multipla"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi per firma multipla"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazioni"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna transazione trovata"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa destinatario"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round confermato"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account from your wallet?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this account"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been deleted"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfers of asset in account"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the asset ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Name"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find asset"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the asset"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default frozen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to opt in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset not found"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully opt in for the asset"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anteprima Account"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun account trovato"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome account"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un account di base"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa Account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea account multifirma"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarda account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trascrivi le 25 parole"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome interno account"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzi"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un account multifirma può elaborare le transazioni solo se gli N account indicati alla creazione dell'account firmano la transazione."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona account esistenti nel tuo wallet"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi gli account degli amici - un account per riga"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona quanti account sono necessari per firmare la transazione"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per creare l'account, assicurati di avere salvato la frase mnemonica. È sicuro mostrare la frase adesso?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra la frase mnemonica"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual è la parola alla posizione n."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scrivi la frase mnemonica nell'ordine mostrato, copiala o fai uno screenshot e salvala in un luogo sicuro e protetto. Non mostrare la frase a nessuno. Il passo successivo sarà quello di confermare che hai salvato la frase correttamente e dopo aver confermato la parola corretta di una posizione specifica salveremo quel conto in un archivio criptato nel tuo browser. Puoi usare questa frase anche senza salvarla, ad esempio in altri portafogli. Assicurati di non perderla mai, nemmeno fra 10 anni"])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia la verifica"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea nuova"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi menemonico"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scarta frase"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan from QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create vanity account"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will start with"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will contain"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will end with"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of addresses processed"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop searching"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of threads to use"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this account"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto lock feature is turned off"])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa wallet"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome del Wallet"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File del Wallet"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importa wallet"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi importare un wallet precedentemente esportato. La password del wallet rimane all'interno del file esportato, quindi puoi accedervi dopo l'importazione con la stessa password di prima. Puoi cambiare la password dopo il login dalla pagina delle impostazioni."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account di base"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account multifirma"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account pubblico"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effettua pagamento - da"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attiva fotocamera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le note sono codificate in Base64. Se abilitato, puoi inserire direttamente Base64 nel campo della nota e inviarlo come dato grezzi alla rete."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account multifirma"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea proposta"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma e invia la proposta"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore inserisci qui la firma dell'amico"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica dati multifirma"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga ad un account del wallet"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga ad un altro account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È preferibile memorizzare il conto a cui si inviano soldi nella rubrica"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissione"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifica pagamento"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore verifica il pagamento"])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dall'account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga all'account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli transazione"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo Round"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo Round"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis ID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etichetta"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firme"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmato"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non firmato"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma con"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia questi dati agli altri firmatari"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia alla rete"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sto inviando il pagamento nella rete"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento inviato nella rete. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In attesa di conferma dalla rete."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conferma ricevuta. Il tuo pagamento è nella blockchain"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probabilmente il pagamento non ha raggiunto la rete. Sei offline? Per favore controlla il tuo account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maxium"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impostazioni"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambiente"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord del wallet"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vecchia password"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password (ripeti)"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup del wallet"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi fare un backup del wallet e importarlo in un altro computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella il wallet"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Robustezza"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password è stata aggiornata"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli transazione"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID transazione"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo transazione"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati grezzi"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati decodificati"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi ricompense"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi importo"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Round confermato"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commissioni"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo valido"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Genesis"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash Genesis"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset intra round"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimo valido"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transazione di pagamento"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importo"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi importo"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinatario"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa destinatario"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tempo del Round"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittente"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ricompensa mittente"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset creato"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome asset"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autore Asset"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager Asset"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riserva Asset"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congela Asset"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revoca Asset"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome Asset unit"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale Asset"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimali Asset"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset predefinito congelato"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quali informazioni private raccogliete su di me ?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessuna. A parte l'indirizzo IP e il browser che viene memorizzato nei log di ogni servizio internet. Non raccogliamo alcun tipo di informazione privata come il tuo nome, email o indirizzo."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cosa memorizzate nei cookie?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nulla. Non usiamo cookie. Usiamo un'archiviazione locale persistente chiamata indexdb per salvare il tuo wallet."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Come raccogliete le informazioni analitiche?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non lo facciamo. Non usiamo nessun tipo di servizio di analisi, ad esempio Google Analytics, in modo che possiate sentirvi più sicuri. Il web è puramente html con javascript, e conosciamo il tuo indirizzo IP solo quando fai richieste al server. A seconda delle tue impostazioni e del tuo algod server o indexer, il server selezionato potrebbe anche conoscere il tuo indirizzo IP."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'uso di questo servizio influisce sulle pubblicità che vedo?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No. I server commerciali non hanno alcuna informazione da parte nostra riguardo all'utilizzo. L'unico modo in cui per esempio Google può sapere che stai usando questo servizio è che tu acceda a questo sito dalla ricerca di Google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chi offre questo servizio?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa ha creato questo servizio. ID Azienda: 51 882 272, VAT ID: SK2120828105, con sede a Bratislava, Slovacchia.  Se questo è in esecuzione nel sito ufficiale a-wallet.net, la società è anche fornitore del servizio. La società non è responsabile di alcun servizio, né di eventuali errori nell'applicazione."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuovo NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra form avanzato"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome asset"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo autore"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi il form avanzato"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea o importa un indirizzo prima"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congelato : Gli account degli utenti dovranno essere scongelati prima di effettuare transazioni"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimali: numero intero di decimali per il calcolo dell'unità dell'asset"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero totale di circolante per questo asset"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilizzato per visualizzare le unità di asset all'utente"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stringa opzionale che punta a un URL relativo all'asset"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash opzionale relativo all'asset. Lunghezza 32 caratteri."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il manager può modificare il manager, la riserva, l'account di congelamento e di revoca per l'asset."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di riserva: L'indirizzo specificato è considerato la riserva dell'asset (non ha privilegi speciali, è solo informativo)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di congelamento: L'indirizzo specificato può congelare o scongelare gli asset dell'utente"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indirizzo di revoca: L'indirizzo specificato può revocare gli asset dell'utente e inviarli ad altri indirizzi"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nota impostata nella transazione di creazione"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea NFT / ASA"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domande frequenti"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the vote coin voting system"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decentralized voting platform based on Algorand message standard 0001 was winner solution at encode hackathon and later participated in encode accelerator program. Main advantage of this voting system is cost effeciency and auditable results by algorand blockchain. Vote coin, similar as algorand governance uses the note field in the transactions as the data storage. Vote coin provides technical solution for knowledge based democracy. Person can delegate his voting power to other person. Delegation may be partial, one can give 50 points to person A and 100 points to person B. Delegation can be also content specific. If person trusts person A in economy matters he can delegate his full voting power to him, while he can delegate full voting power in matter of IT tech to person B. Casting of the votes can be changed, and the last vote in the voting round is valid. Voting can be used as 1 vote = 1 vote, or 1 person = 1 vote. Quadratic voting can be applied if DAO requires it. Voting can be used by DAOs, corporations, governments or individuals. Audit services may be purchased."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I have my custom ASA as basis for 1 vote = 1 ASA token?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. This is the main use case. DAO usually has distributed their tokens to the owners. DAO asks a question which costs 0.001 algo. DAO members vote or delegate their voting power to other members. After voting is finished, auditors publish the results. At the end, DAO calculates results or takes the results of the auditors, and publish the final vote result. From this message the action may be triggered, for example the github PR merge."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is delegation pernament?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the delegation within scope of the asset is pernament until it has been changed. When person trusts the politician, he delegates him his voting power. Person can change his delegation preferences any time. The decision point for vote calculation is the final block of the voting. If person A votes and person B does not vote, if person B delegate his voting power to person A 1 block before end of the voting, his voting power for this voting session will be transfered."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you call it knowledge based pure democracy system?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregation of wisdom is ensured by categorized delegation. Every question is context based. Some questions requires IT knowledge, some questions requires knowledge of laws. If person A trusts person B and C in IT matters, he can delegate for example 20 IT points to person B, 30 IT points to person C, and 20 Legal points to person D. If there is question regarding the IT stuff, for example github PR merge request and person A does not vote, he delegates his voting power to 20/50 to person B, and 30/50 to person C. Person B can delegate his voting power to other persons he knows they do good decisions, for example 100 IT points to person C. If only person C votes, he receives the voting power from person A, B, and himself. Delegation of power does not mean that person cannot vote. It is pure democratic system which means that if person votes, he always represents his voting power, and his voting power is not being used by anyone else."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please spread the word on youtube, twitter or other means. We have active engagement program where you can receive Vote Coin tokens."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are developer, please take a look at the engagement program. If you create open source solution based on the vote coin standard, you can receive vote coin token rewards."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you represent a DAO or corporation, please support us by purchasing Auditing services. We will calculate the results of voting for you so that you can be ensured you have the correct results."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much will it cost me?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are DAO, it will cost you 0.002 algo per question - you ask a question through blockchain, and set final results."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your DAO members will pay pay 0.001 algo to cast votes. They can delegate their voting power for 0.001 to other persons so that they do not have to vote in each question, but rather delegate their voting power to trusted person."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If DAO wants to manage their own list of allowed person, in one transaction it can add approx 15 accounts to the trusted list."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are single person who wants to ask a question, just place a question to the algo token."])}
  }
}