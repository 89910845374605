export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error 404 - Pagina niet gevonden"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De pagina die je zoekt is niet gevonden"])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo Portemonnee"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe portemonnee"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee name"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee password"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak portemonnee"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonnee"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw portemonnee wordt opgeslagen in uw browser. Portemonnee wachtwoord is vereist om uw portemonnee te openen en uw accounts hierin te zien of om een verrichting te tekenen."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wij adviseren een wachtwoord lengte van tenminste 12 characters, met het gebruik van kleine letters, hoofdletters een nummer en een speciaal teken."])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open portemonnee"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer portemonnee"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee wachtwoord"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterkte"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open portemonnee"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic usage"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to use Tether  (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AWallet source code"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ga terug"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar homepagina"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieer adres"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gekopieerd naar uw klembord"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop camera"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading.."])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een portemonnee"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Navigatie schakel"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon mijn accounts"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde Vragen"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy beleid"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitloggen"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])}
  },
  "votemenu": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "my_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My questions"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "delegation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegation"])},
    "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "trusted_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list"])},
    "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governance"])},
    "ams01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Coin"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "voteask": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will show up in the table list of questions"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the question"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address with more question details (start with https://)"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add here url address where people can find more information about the question"])},
    "max_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of blocks since now until the question will be closed"])},
    "calculated_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max block"])},
    "calculated_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "response_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the response option"])},
    "remove_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove option"])},
    "responses_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses."])},
    "add_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new response"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge based category vote delegation in effect"])},
    "submit_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "encryptedVotingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow members to use encrypted voting"])},
    "encryptionAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption address - Generate new mnemonics for each voting and store it securly"])},
    "generateNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new encryption mnemonics"])}
  },
  "votedelegate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting power delegation"])},
    "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can delegate your voting power to someone you trust. It may be your friend or some public person."])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision."])},
    "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power."])},
    "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined."])},
    "category_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any category"])},
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
    "wallet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
    "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account to delegation"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegate specific category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify your delegation in knowledge based category.. eg IT, finance, governance"])},
    "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add specific category"])},
    "submit_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the button below, you will store your delegation preferences to the blockchain."])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store delegation to the blockchain for ", _interpolate(_named("accountName"))])}
  },
  "voteoverview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
  },
  "votequestions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])}
  },
  "votequestionlist": {
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "no_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be the first one to ask a question"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question text"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed at round"])},
    "maxround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open until round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List all questions"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question id"])},
    "current_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current round"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of the round"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "voting_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting has been closed"])},
    "latest_response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Latest vote for the account ", _interpolate(_named("accountName")), " is selected"])},
    "vote_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cast your vote"])},
    "sum_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted votes"])},
    "sum_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted coins"])},
    "trusted_list_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list result"])},
    "hypercapitalism_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 coin 1 vote results"])},
    "vote_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point."])},
    "check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check results"])},
    "sbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple balance results"])},
    "sbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "qbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic balance results"])},
    "qbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "ssar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple single account results"])},
    "ssar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic single account results"])},
    "qsar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "stlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple trusted list results"])},
    "stlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qtlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic trusted list results"])},
    "qtlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "encryptVoteCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypt my voting decision"])},
    "submit_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit my results for audit purposes"])},
    "mnemonics_required_to_get_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted voting requires mnemonics to properly calculate the results"])}
  },
  "votetl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list management"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add accounts to trusted list - one account per line"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove accounts from trusted list - one account per line"])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store trusted list to blockchain for ", _interpolate(_named("accountName"))])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added in the round n."])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted account"])},
    "no_tl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no trusted accounts listed for the account ", _interpolate(_named("accountName")), "."])}
  },
  "voteanswerslist": {
    "no_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no response yet to the question"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response json"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment gateway"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to the algorand address"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["please"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matching symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network fee"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using QR code"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using algorand wallet"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay using web wallet"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel payment"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment received"])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to merchant"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Waiting for the payment"])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang transactie"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang transactie naar adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over decimalen voor een QR"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesuggereerd label voor ontvangst adres"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie is bewerkbaar door gebruikber"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account overzicht"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een betaling"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvang betaling"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag zonder wachtende beloningen"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beloningen"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtende beloningen"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belonings basis"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronde"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties locale staat"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicaties totale schema"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activaten"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakte applicaties"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature drempel"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature addressen"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ververs"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transacties"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen transacties gevonden"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zender"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger beloningen"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigde ronde"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kost"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete account"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this account from your wallet?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete this account"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account has been deleted"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accept transfers of asset in account"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I know the asset ID"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset ID"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Name"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find asset"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information about the asset"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit name"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimals"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default frozen"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creator address"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager address"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback address"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freeze address"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve address"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in to asset"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to pay 0.001 algo to opt in to this asset? After you opt in, you will be able to receive this asset to your account."])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to opt in"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset not found"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully opt in for the asset"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accounts overzicht"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen accounts gevonden"])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account naam"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw account"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een basis account"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer account"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een multisignature account"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk account"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf uw 25 woorden geheugensteuntje op."])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intern account naam"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak account"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een multisignature account kan alleen transacties vormen als N accounts vermeld bij het maken van uw account de transactie tekenen."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer bestaande accounts in uw portemonnee"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg uw vrienden toe - een account per regel"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer hoeveel accounts moeten tekenen"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als u een account wilt maken moet u uw 24 woorden geheugensteuntje opschrijven. Is het veilig om hem nu te laten zien?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon gegeuhensteun"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat is het woord op de n.e positie"])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schrijf uw geheugensteun woorden in getoonde volgorde op, kopieer ze of maak er een foto van. De volgende stap zal zijn om enkele woorden uit deze zin te bevestigen en wij zullen het opslaan in de gecodeerde opslag in uw browser. Het is veilig om deze geheugensteun op te slaan en dit adres te gebruiken zonder het op de account op te slaan. Zorg ervoor dat je de geheugensteun zin nooit verliest."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start de uitdaging"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak nieuw"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg geheugensteun"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat zin vallen"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan from QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create vanity account"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will start with"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will contain"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address will end with"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of addresses processed"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find the address"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop searching"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of threads to use"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use this account"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto lock feature is turned off"])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonee"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee naam"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee bestand"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importeer portemonnee"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt eerder geëxporteerde portemonnees importeren. Het wachtwoord van de portemonnee blijft in de geëxporteerde portemonnee, zodat u deze na het importeren kunt openen met hetzelfde wachtwoord als voorheen. U kunt het wachtwoord wijzigen nadat u zich hebt aangemeld via de instellingenpagina."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basis account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature accout"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publiek account"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak een betaling - van"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle camera"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie is Base64 gecodeerd. Indien ingeschakeld kunt u base64 invoer in het notitieveld plaatsen en het als onbewerkte gegevens naar het netwerk sturen."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geselecteerd account"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multisignature account"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorstel maken"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teken & verstuur voorstel"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul hier de handtekening van uw vriend in"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laad de multisignature data"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar portemonnee account"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar ander account"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het is beter om de rekening waarnaar u geld stuurt in het adresboek op te slaan"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzie betaling"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzie uw betaling svp."])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Van account"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verstuur naar account"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie details"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naam"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EersteRonde"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TweedeRonde"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naar"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handtekeningen"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondertekend"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niet ondertekend"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teken met"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Onderteken"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur deze gegevens naar andere ondertekenaars"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stuur naar het netwerk"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling wordt naar het netwerk verstuurt"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling naar het network verstuurd. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtend voor netwerk confirmaties."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmaties zijn ontvangen, uw betaling is in het block!"])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activaten"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De betaling heeft het netwerk waarschijnlijk niet bereikt. Bent u offline? Controleer uw account"])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set maxium"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Omgeving"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["host"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portemonnee wachtwoord"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oud wachtwoord"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw wachtwoord - herhalen"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backup portemonnee"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt een back-up van uw portemonnnee maken en importeren naar een andere computer."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak backup"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder uw portemonnee"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sterkte"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord is geupdate"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom server settings"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register protocol handler"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register web+algorand:// handler"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully updated the protocol handler"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie details"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tramsactie ID"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactie type"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notitie"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruwe data"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedecodeerde data"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluit beloningen"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitend bedrag"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevestigde ronde"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergoeding"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eerste geldig"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intra-ronde afwijking"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laatste geldig"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalings transactie"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedrag"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluitend bedrag"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ontvanger beloning"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ronde tijd"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzender"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzender beloning"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ondertekening"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemaakt activa"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa naam"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa maker"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa manager"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa reserve"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activa vriezen"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa terugvordering"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa eenheid naam"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa totaal"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa decimalen"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa standaard bevroren"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa url"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activa metadata"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tijd"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Group ID"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy beleid"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welke privé-informatie over mij verzamel je?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen. Behalve IP-adres en browser, die voor elke internetdienst in logboeken wordt opgeslagen. Wij verzamelen geen enkele vorm van prive-informatie zoals uw naam, email of adres."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wat sla je op in cookies?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niets. Wij gebruiken geen cookies. We gebruiken persistente lokale opslag genaamd indexdb voor het bewaren van uw portemonnee."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hoe verzamel je analytische informatie?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dat doen we niet. Wij gebruiken geen enkele vorm van analytics service bv google analytics, zodat u zich veiliger kunt voelen. Het web is puur html met javascript, en wij kennen uw IP adres alleen als u de server opvraagt. Afhankelijk van uw instellingen en uw algod server of indexer, kan de geselecteerde server ook uw IP-adres weten."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heeft het gebruik van deze dienst invloed op de advertenties die ik zie?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee. Commerciële servers hebben geen informatie van ons over enig gebruik. De enige manier waarop bijvoorbeeld google kan weten dat u deze dienst gebruikt is dat u deze site bezoekt via google zoekopdracht."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie verleent deze dienst?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa heeft deze dienst gecreëerd. Bedrijfs ID: 51 882 272, BTW ID: SK2120828105, gevestigd in Bratislava, Slowakije.  Als dit draait op de officiële website a-wallet.net, is het bedrijf ook dienstverlener. Het bedrijf is niet aansprakelijk voor de dienst, noch voor fouten in de toepassing."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuw NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toon geavanceerd formulier"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vriendelijke name"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maker adres"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verberg geavanceerd formulier"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak adres of importeer adres eerst"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevroren : Gebruikersaccounts moeten worden gedeblokkeerd alvorens transacties te verrichten"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decimalen: geheel aantal decimalen voor berekening activa-eenheid"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal aantal van dit goed beschikbaar voor circulatie"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruikt om activa eenheden te tonen aan de gebruiker"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele string die verwijst naar een URL met betrekking tot het activa"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionele hash-verbintenis van een soort met betrekking tot het goed. Lengte 32 tekens."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheerder kan beheerder beamen, reserve, bevriezing en terugvorderingsrekening voor het goed wijzigen."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve adres: Gespecificeerd adres wordt beschouwd als de activareserve (het heeft geen speciale voorrechten, dit is slechts informatief)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bevries adres: Gespecificeerd adres kan tegoeden van gebruiker bevriezen of deblokkeren"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terugvordering-adres: Gespecificeerd adres kan tegoeden van gebruikers intrekken en naar andere adressen sturen"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking ingesteld in de aanmaak transactie"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak NFT / ASA"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veelgestelde vragen"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the vote coin voting system"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decentralized voting platform based on Algorand message standard 0001 was winner solution at encode hackathon and later participated in encode accelerator program. Main advantage of this voting system is cost effeciency and auditable results by algorand blockchain. Vote coin, similar as algorand governance uses the note field in the transactions as the data storage. Vote coin provides technical solution for knowledge based democracy. Person can delegate his voting power to other person. Delegation may be partial, one can give 50 points to person A and 100 points to person B. Delegation can be also content specific. If person trusts person A in economy matters he can delegate his full voting power to him, while he can delegate full voting power in matter of IT tech to person B. Casting of the votes can be changed, and the last vote in the voting round is valid. Voting can be used as 1 vote = 1 vote, or 1 person = 1 vote. Quadratic voting can be applied if DAO requires it. Voting can be used by DAOs, corporations, governments or individuals. Audit services may be purchased."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I have my custom ASA as basis for 1 vote = 1 ASA token?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. This is the main use case. DAO usually has distributed their tokens to the owners. DAO asks a question which costs 0.001 algo. DAO members vote or delegate their voting power to other members. After voting is finished, auditors publish the results. At the end, DAO calculates results or takes the results of the auditors, and publish the final vote result. From this message the action may be triggered, for example the github PR merge."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is delegation pernament?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the delegation within scope of the asset is pernament until it has been changed. When person trusts the politician, he delegates him his voting power. Person can change his delegation preferences any time. The decision point for vote calculation is the final block of the voting. If person A votes and person B does not vote, if person B delegate his voting power to person A 1 block before end of the voting, his voting power for this voting session will be transfered."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you call it knowledge based pure democracy system?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregation of wisdom is ensured by categorized delegation. Every question is context based. Some questions requires IT knowledge, some questions requires knowledge of laws. If person A trusts person B and C in IT matters, he can delegate for example 20 IT points to person B, 30 IT points to person C, and 20 Legal points to person D. If there is question regarding the IT stuff, for example github PR merge request and person A does not vote, he delegates his voting power to 20/50 to person B, and 30/50 to person C. Person B can delegate his voting power to other persons he knows they do good decisions, for example 100 IT points to person C. If only person C votes, he receives the voting power from person A, B, and himself. Delegation of power does not mean that person cannot vote. It is pure democratic system which means that if person votes, he always represents his voting power, and his voting power is not being used by anyone else."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please spread the word on youtube, twitter or other means. We have active engagement program where you can receive Vote Coin tokens."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are developer, please take a look at the engagement program. If you create open source solution based on the vote coin standard, you can receive vote coin token rewards."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you represent a DAO or corporation, please support us by purchasing Auditing services. We will calculate the results of voting for you so that you can be ensured you have the correct results."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much will it cost me?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are DAO, it will cost you 0.002 algo per question - you ask a question through blockchain, and set final results."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your DAO members will pay pay 0.001 algo to cast votes. They can delegate their voting power for 0.001 to other persons so that they do not have to vote in each question, but rather delegate their voting power to trusted person."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If DAO wants to manage their own list of allowed person, in one transaction it can add approx 15 accounts to the trusted list."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are single person who wants to ask a question, just place a question to the algo token."])}
  }
}