export default {
  "404": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba 404 - Stránka nebola nájdená"])},
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka ktorú hľadáte nie je bohužiaľ k dispozícii. Skúste prejsť na hlavnú stránku."])}
  },
  "footer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algo Peňaženka"])}
  },
  "login": {
    "new_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová peňaženka"])},
    "new_wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov peňaženky"])},
    "new_wallet_pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo peňaženky"])},
    "new_wallet_button_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť peňaženku"])},
    "new_wallet_button_import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať peňaženku"])},
    "new_wallet_help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša peňaženka bude uložená v prehliadači. Heslo je potrebné k odomknutiu peňaženky a možnosti odosielania vašich aktív."])},
    "new_wallet_help2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odporúčame použiť veľmi silné heslo. Aspoň 12 znakov s použitím znakov zo skupín malé písmená, veľké písmená, číslice a špeciálne znaky"])},
    "open_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoriť peňaženku"])},
    "select_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať peňaženku"])},
    "wallet_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo do peňaženky"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila hesla"])},
    "new_wallet_button_open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otvoriť peňaženku"])},
    "basic_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základné použitie"])},
    "tether_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako používať Tether (USDt)"])},
    "source_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdrojový kód AWallet"])}
  },
  "global": {
    "go_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naspäť"])},
    "go_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domov"])},
    "copy_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírovať adresu"])},
    "copied_to_clipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírované"])},
    "stop_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vypnúť kameru"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Áno"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šťahujem údaje.."])}
  },
  "navbar": {
    "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Wallet"])},
    "toggle_nav": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prepnúť navigáciu"])},
    "list_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoznam mojich účtov"])},
    "new_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový účet"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktíva"])},
    "asset_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť NTF"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "privacy_policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ochrana osob. údajov"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásenie"])},
    "vote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting"])}
  },
  "votemenu": {
    "overview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])},
    "my_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My questions"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])},
    "delegation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegation"])},
    "answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Answers"])},
    "trusted_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list"])},
    "governance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governance"])},
    "ams01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vote Coin"])}
  },
  "govtoolsmenu": {
    "gen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate accounts"])},
    "distribute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribute algo 1->N"])},
    "optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opt in for the governance"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mass pay N->1"])},
    "merge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merge accounts N->1"])}
  },
  "voteask": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "title_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This will show up in the table list of questions"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the question"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address with more question details (start with https://)"])},
    "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add here url address where people can find more information about the question"])},
    "max_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of blocks since now until the question will be closed"])},
    "calculated_block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max block"])},
    "calculated_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estimated time"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code"])},
    "response_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text of the response option"])},
    "remove_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove option"])},
    "responses_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["People will answer your question by voting for specific responses. Please provide codes and texts of 2-N responses."])},
    "add_response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new response"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knowledge based category vote delegation in effect"])},
    "submit_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question through blockchain"])},
    "encryptedVotingEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow members to use encrypted voting"])},
    "encryptionAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encryption address - Generate new mnemonics for each voting and store it securly"])},
    "generateNewAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate new encryption mnemonics"])}
  },
  "votedelegate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting power delegation"])},
    "intro1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can delegate your voting power to someone you trust. It may be your friend or some public person."])},
    "intro2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you have good friend in finance, you can delegate him voting power in finance category. If he does not vote for questions directly, he might delegate your voting power and his voting power according his wisdom to wise person he know. When the wise person votes, he uses your voting power and he represents your opinions. You can always vote directly in all matters even if you have the delegation. If you vote directly, your voting power stays only at your discrete decision."])},
    "intro3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can split your voting power to several friends. You set the weight of your voting power distribution in points. If you set 50 points for first friend and 100 points for second friend, first friend will have 50/150 = 33% of your voting power. Second friend will have the rest 100/150 = 67% of your voting power."])},
    "intro4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category any is the fallback category. If category delegation for specific question is not defined, the any category will be used if defined."])},
    "category_any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Any category"])},
    "add_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account"])},
    "wallet_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet address"])},
    "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add account to delegation"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delegate specific category"])},
    "category_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify your delegation in knowledge based category.. eg IT, finance, governance"])},
    "add_category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add specific category"])},
    "submit_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By clicking the button below, you will store your delegation preferences to the blockchain."])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store delegation to the blockchain for ", _interpolate(_named("accountName"))])}
  },
  "voteoverview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])}
  },
  "votequestions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions"])}
  },
  "votequestionlist": {
    "question_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question title"])},
    "no_questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Be the first one to ask a question"])},
    "question_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question text"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Placed at round"])},
    "maxround": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open until round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List all questions"])},
    "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question id"])},
    "current_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current round"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time of the round"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "voting_closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voting has been closed"])},
    "latest_response": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Latest vote for the account ", _interpolate(_named("accountName")), " is selected"])},
    "vote_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hlasuj"])},
    "sum_trusted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted votes"])},
    "sum_coins": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted coins"])},
    "trusted_list_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list result"])},
    "hypercapitalism_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 coin 1 vote results"])},
    "vote_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to vote, select your preferences please. You can assign your preferences for each answer with rating from zero to 100 points. If you want to select only one answer, please give it 100 points, and other answers to zero points. If you do not know, you do not have to vote, or you can assign all answers the equal number of points. If you vote for one answer in 100 points, and other answer 20 points, your voting power for first answer will be 100/120 = 83% and voting power distribution for second answer will be 17%. If you assign all options 100 points, your voting power distribution will be the same as if you assign all options 1 point."])},
    "check_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check results"])},
    "sbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple balance results"])},
    "sbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "qbr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic balance results"])},
    "qbr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sum of accounted tokens"])},
    "ssar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple single account results"])},
    "ssar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qsar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic single account results"])},
    "qsar_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "stlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple trusted list results"])},
    "stlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "qtlr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quadratic trusted list results"])},
    "qtlr_sum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of accounted accounts"])},
    "encryptVoteCast": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypt my voting decision"])},
    "submit_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit my results for audit purposes"])},
    "mnemonics_required_to_get_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encrypted voting requires mnemonics to properly calculate the results"])}
  },
  "votetl": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted list management"])},
    "help1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questioner can manage list of trusted accounts on this page. Each person may have only one trusted account. The management of this must be provided by the questioner. After the voting will be finished, there are two calculation of votes - one person one vote, and one coin one vote. The voting system does not interpret what the result of voting means. The voting system just calculates the results of votings according to the voting system rules, eg the delegation of votes, proportion of votes, validity of votes and more."])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add accounts to trusted list - one account per line"])},
    "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove accounts from trusted list - one account per line"])},
    "submit_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Store trusted list to blockchain for ", _interpolate(_named("accountName"))])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Added in the round n."])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted account"])},
    "no_tl": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["There are no trusted accounts listed for the account ", _interpolate(_named("accountName")), "."])}
  },
  "voteanswerslist": {
    "no_answers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no response yet to the question"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response round"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response time"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responder"])},
    "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Response json"])}
  },
  "merchant": {
    "make_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platobná brána"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaptaťte"])},
    "to_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na algorand účet"])},
    "please": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sieť"])},
    "matching_symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Párovací symbol"])},
    "network_fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok za prevod"])},
    "pay_qr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť pomocou QR kódu"])},
    "pay_nativewallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť cez algo peňaženku"])},
    "pay_webwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť cez webovú peňaženku"])},
    "cancel_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť platbu"])},
    "payment_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba bola prijatá."])},
    "go_back_to_merchant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ísť späť na webstránku obchodníka"])},
    "waiting_for_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čaká sa na platbu. Zaplaťte prosím."])}
  },
  "gateway": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free opensource payment gateway without middleman"])},
    "perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start accepting algo, USDC, USDT or other assets on your website. By accepting cryptocurrency without financial middleman you will increase your sales on your website and increase your profit."])},
    "how": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How it works?"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create unique matching symbol, eg. order id"])},
    "redirect_to_gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirect user to the gateway"])},
    "customer_pays": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer pays directly to your algorand account"])},
    "customer_redirected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer will be redirected to your website back after successful payment"])},
    "use_your_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use your money. You can echange USDT and take USD from the exchange, or you can pay with USDT for other goods or services"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is it secure?"])},
    "you_make_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You make the payment details. You setup the successful and cancel URL address. We notify you when the payment is done. You have to check in your backend where you accept the payment that it has been received and you notify the customer."])},
    "opensource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This project is open source. You can clone, modify it, or run in your private environment for commercial purposes"])},
    "just_website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The payment gateway is just HTML website with javascript. No other backend is used other than official algorand nodes."])},
    "no_restrictions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have AWallet account in order to use the gateway."])},
    "allowed_parameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "parameters_perex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can set any of these parameters. Only required field is the address where you want to receive your payment and transaction amount. The payment address consits of 2 parts. Payment info, and gateway settings. Both fields are base64 encoded separated by /. Please make sure that you url encode them properly. Payment info is the basic algorand protocol address used for example in the QR codes."])},
    "payment_info_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed parameters"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["use the asset base unit. Eg. 1 ALGO = 1000000"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["the amount in microalgos. Eg. 0,001 ALGO = 1000"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network for debug purposes"])},
    "settings_params": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed settings"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after successful payment"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address where to redirect client after he clicks cancel payment"])},
    "example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Example"])},
    "turn_on_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn on the build tool"])},
    "turn_off_build_tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turn off the build tool"])},
    "tether": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tether mainnet"])},
    "algorand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Algorand"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can specify asset id for the transaction"])},
    "error_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill in at least account, amount and asset"])},
    "error_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL address must start with https:// protocol"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated button"])},
    "payment_info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment info"])},
    "callback_configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Callback configuration"])},
    "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generated link"])},
    "html_example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HTML link example"])}
  },
  "receive": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijať platbu"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa príjemcu platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácia o desatinnej čiarke do kódu"])},
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Návrh označenia účtu pre odosielateľa"])},
    "noteeditable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka je editovateľná odosielateľom"])}
  },
  "acc_overview": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad účtov"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať platbu"])},
    "receive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijať platbu"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "amount_without_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka bez neprijatých odmien"])},
    "rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmeny"])},
    "pending_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neprijaté odmeny"])},
    "reward_base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základ odmien"])},
    "round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo bloku"])},
    "apps_local_state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav lokálnych aplikácií"])},
    "apps_total_schema": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková schéma aplikácií"])},
    "assets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktíva"])},
    "created_apps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorené aplikácie"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "multisignature_threshold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpis limit"])},
    "multisignature_addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpis adresa"])},
    "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcie"])},
    "no_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatiaľ na účte nie sú vykonané žiadne transakcie alebo sa nenačítali"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
    "tr_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre príjemcu"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený blok"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "delete_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "delete_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ste si istý, že chcete tento účet vymazať?"])},
    "delete_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať účet"])},
    "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet bol vymazaný"])},
    "asset_optin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať aktívum"])},
    "ask_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask question"])}
  },
  "optin": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijímať aktívum (ASA alebo NFT) na účte"])},
    "searchById": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viem ID aktíva"])},
    "assetId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID aktíva"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť aktívum"])},
    "assetInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácia o aktíve"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkovo"])},
    "unit_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des. miesta"])},
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
    "metadata_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata hash"])},
    "default_frozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predvolene zamrznuté"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa tvorcu aktíva"])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa managera aktíva"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa clawback účtu"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykateľný účet aktíva"])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervný účet aktíva"])},
    "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcia"])},
    "optin_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovať aktívum"])},
    "dialog_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptovať aktívum"])},
    "optin_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ste si istý, že chcete vykonať prevod za poplatok 0.001 algo a umožňniť prijímať aktívum?"])},
    "optin_confirm_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcem prijímať aktívum"])},
    "asset_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum nebolo nájdené"])},
    "asset_opt_in_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešne ste sa v sieti prihlásili k prijímaniu aktíva"])}
  },
  "accounts": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad účtov"])},
    "no_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevytvorili ste si zatiaľ žiadny účet. Pokračujte vytvorením účtu alebo importovaním účtu."])},
    "account_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov účtu"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba"])}
  },
  "newacc": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový účet"])},
    "create_basic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť bežný účet"])},
    "import_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import bežného účtu"])},
    "create_multisign_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť mulitipodpisový účet"])},
    "watch_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať verejný účet"])},
    "write_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapíšte si 25 slov mnemotechnickej frázy"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interný názov účtu"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "multisig_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisové účty môžu odoslať prostriedky iba ak limitný počet účtov podpíše transakciu."])},
    "select_account_from_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybrať účet pre podpis zo zoznamu účtov"])},
    "add_other_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ďalšie účtu - jeden účet na riadok"])},
    "trashold_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte limitný počet účtov ktorý je potrebný pre podpísanie transakcie"])},
    "create_account_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aby ste si mohli vytvoriť účet, uistite sa, že ste si zapísali mnemotechnickú frázu. Je bezpečné zobraziť frázu teraz?"])},
    "show_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť mnemotechnickú frázu"])},
    "position_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aké je slovo na pozícii č."])},
    "mnemonic_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapíšte si mnemotechnickú frázu v stanovenom poradí, skopírujte si ju na bezpečné zaheslované miesto alebo si urobte screenshot. Nikomu túto frázu neukazujte. Ďalší krok bude potvrdenie, že ste si frázu uložili správne a po potvrdení správneho slova na konkrétnej pozícii uložíme tento účet do zašifrovanej peňaženky vo Vašom prehliadači. Môžete použiť túto frázu aj bez toho aby ste si ju uložili npr v iných peňaženkách. Uistite sa, že ju nikdy ani o 10 rokov nestratíte."])},
    "start_challenge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začať test"])},
    "create_new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť novú"])},
    "hide_mnemonic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skryť frázu"])},
    "drop_phrase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahodiť frázu"])},
    "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascanovať z QR"])},
    "create_vanity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet s vlastnou adresou"])},
    "vanity_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začne na"])},
    "vanity_mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsahuje"])},
    "vanity_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Končí na"])},
    "vanity_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet otestovaných účtov"])},
    "vanity_button_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájsť účet"])},
    "vanity_button_stop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop"])},
    "vanity_workers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vlákien"])},
    "vanity_use": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použiť tento účet"])},
    "auto_lock_off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto zamykanie peňaženky je vypnuté"])}
  },
  "import": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import peňaženky"])},
    "wallet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov peňaženky"])},
    "wallet_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor peňaženky"])},
    "import_wallet_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať peňaženku"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si importovať vyexportovanú peňaženku. Heslo ostáva rovnaké ako v predchádzajúcej peňaženke. Peňaženka sa dá exportovať v nastaveniach, kde sa dá zmeniť aj heslo."])}
  },
  "acc_type": {
    "basic_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bežný účet"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisový účet"])},
    "public_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verejný účet"])}
  },
  "pay": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová platba - od"])},
    "toggle_camera": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapnúť/Vypnúť kameru"])},
    "note_is_b64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka je v base64. Ak vložíte poznámku v base64, odošle sa ako raw data pri vytváraní transakcie."])},
    "selected_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vybraný účet"])},
    "multisig_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multipodpisový účet"])},
    "create_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť návrh"])},
    "sign_proposal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísať & odoslať návrh"])},
    "signature_from_friend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte sem podpis od Vášho priateľa"])},
    "load_multisig_data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Načítať údaje z podpisu"])},
    "pay_to_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na účet z peňaženky"])},
    "pay_to_other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na iný účet"])},
    "store_other_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je lepšie si uložiť účet do zoznamu adries pred vykonaním platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "review_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náhľad platby"])},
    "review_payment_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte si poctivo platobné údaje. Platba na nevalidný účet sa nedá vrátiť späť."])},
    "from_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Z účtu"])},
    "pay_to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaplatiť na účet"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková čiastka"])},
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detaily transakcie"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "first_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvý blok"])},
    "last_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledný blok"])},
    "genesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GenesisID"])},
    "tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
    "to_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na účet"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpisy"])},
    "signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísaný"])},
    "not_signed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nepodpísaný"])},
    "sign_with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísaný s"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpísaný"])},
    "send_to_other_signators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlite ďalším podpisovateľom"])},
    "send_to_network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať transakciu do siete"])},
    "state_sending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielam prevod validátorom do siete"])},
    "state_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba bola odoslaná do siete. Tx: "])},
    "state_waiting_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čaká sa na potvrdenie transakcie v bloku."])},
    "state_confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie bolo prijaté. Vaša platba je v bloku č."])},
    "transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba"])},
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívum"])},
    "state_error_not_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba pravdepodobne nedorazila do siete. Máte prístup k internetu? Skontroolujte si stav na účte."])},
    "set_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastaviť maxium"])}
  },
  "settings": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia"])},
    "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server"])},
    "environment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostredie"])},
    "mainnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mainnet"])},
    "testnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testnet"])},
    "devnet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reach Devnet"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "host": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hostiteľ"])},
    "token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["token"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jazyk"])},
    "pass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo k peňaženke"])},
    "oldpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Staré heslo"])},
    "newpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
    "repeatpass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo - kontrola"])},
    "backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záloha peňaženky"])},
    "backup_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete si zazálohovať peňaženku alebo si ju otvoriť v inom počítači."])},
    "create_backup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť zálohu"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať peňaženku"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sila hesla"])},
    "updated_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo bolo upravené"])},
    "custom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastné nastavenie serverov"])},
    "protocol_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrácia protokolu"])},
    "protocol_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrovať protokol web+algorand://"])},
    "protocol_change_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protokol bol úspešne zaregistrovaný"])}
  },
  "transaction": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detail transakcie"])},
    "tr_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID transakcie"])},
    "tr_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ transakcie"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka"])},
    "note_raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dáta"])},
    "note_decoded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dekódovaná poznámka"])},
    "tr_close_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá odmena"])},
    "closing_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá čiastka"])},
    "confirmed_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdený blok"])},
    "fee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatok"])},
    "first_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvý platný blok"])},
    "genesis_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis id"])},
    "genesis_hash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genesis hash"])},
    "intra_round": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offset interné čísla bloku"])},
    "last_valid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledný platný"])},
    "payment_tr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia platby"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiastka"])},
    "close_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzavretá čiastka"])},
    "receiver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príjemca"])},
    "receiver_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre príjemcu"])},
    "round_time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas vytvorenia bloku"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielateľ"])},
    "sender_rewards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmena pre odosielateľa"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpis"])},
    "created_asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorené aktívum"])},
    "asset_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "asset_creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvorca aktíva"])},
    "asset_manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager aktíva"])},
    "asset_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervný účet aktíva"])},
    "asset_freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykateľný účet aktíva"])},
    "asset_clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet clawback"])},
    "asset_unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotka"])},
    "asset_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet aktíva"])},
    "asset_decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet desatinných miest aktíva"])},
    "asset_defaultfrozen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predvolené je zamknuté"])},
    "asset_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa aktíva"])},
    "asset_metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metadata aktíva"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas"])},
    "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID skupiny transakcií"])}
  },
  "privacy": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásady ochrany osobných údajov"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aké osobné informácie o mne zhromažďujete?"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne. Okrem IP adresy a prehliadača, ktorý je uložený v protokoloch pre každú internetovú službu. Nezhromažďujeme žiadnu formu súkromných informácií, ako je vaše meno, e-mail alebo adresa."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo ukladáte do cookies? "])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nič. Nepoužívame cookies. Na ukladanie vašej peňaženky používame trvalé miestne úložisko s názvom indexdb."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ako zhromažďujete analytické informácie?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezhromažďujeme. Nepoužívame žiadnu formu analytických služieb, napr. Google Analytics, aby ste sa cítili bezpečnejšie. Web je čisto html s javascriptom a vašu IP adresu poznáme iba z requestov na server. V závislosti od Vašich nastavení a Vášho servera algod alebo indexer môže aj vybraný server spoznať vašu adresu IP."])},
    "q4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovplyvňuje použitie tejto služby reklamy ktoré vidím?"])},
    "a4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie. Komerčné servery od nás nemajú žiadne informácie o žiadnom použití. Jediným spôsobom, ako môže napríklad spoločnosť Google vedieť, že túto službu používate, je prístup na tieto stránky z vyhľadávania Google."])},
    "q5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kto poskytuje túto službu?"])},
    "a5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scholtz & Company, jsa has created this service. IČO: 51 882 272, IČ DPH: SK2120828105. Ak to beží na oficiálnych webových stránkach poskytovaných spoločnosťou a-wallet.net je tiež poskytovateľom služieb. Spoločnosť nezodpovedá za žiadne služby ani chyby v aplikácii."])}
  },
  "assetcreate": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové NTF / ASA"])},
    "show_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť pokročilý formulár"])},
    "assetName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov aktíva"])},
    "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa tvorcu"])},
    "hide_advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skry pokročilý formulár"])},
    "creator_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najskôr si vytvorte adresu prosím"])},
    "default_fronzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmrazené: Pred uskutočnením transakcie bude potrebné zrušiť zmrazenie používateľských účtov"])},
    "decimals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desatinné miesta: celé číslo desatinných miest pre výpočet jednotky aktíva"])},
    "totalIssuance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový počet aktíva k dispozícii v obehu"])},
    "unitName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov jednotky aktíva"])},
    "assetURL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL adresa smerujúca na miesto ktoré popisuje aktívum"])},
    "assetMetadataHash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hash aktíva. Dĺžka 32 znakov."])},
    "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manager môže upraviť adresy managera, rezervy, uzamykajúceho účtu a clawback účtu pre aktívum."])},
    "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervná adresa: Zadaná adresa sa považuje za rezervu majetku (nemá žiadne zvláštne privilégiá, má iba informačný charakter)"])},
    "freeze": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uzamykajúci účet: Zadaná adresa môže zmraziť alebo zrušiť zmrazenie pozícií majetku používateľa"])},
    "clawback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clawback účet: Zadaná adresa môže zrušiť držbu prostriedkov používateľa a poslať ich na iné adresy"])},
    "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámka uvedená v transakcii vytvorenia"])},
    "create_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť NFT / ASA"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Často kladené otázky"])},
    "q1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About the vote coin voting system"])},
    "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decentralized voting platform based on Algorand message standard 0001 was winner solution at encode hackathon and later participated in encode accelerator program. Main advantage of this voting system is cost effeciency and auditable results by algorand blockchain. Vote coin, similar as algorand governance uses the note field in the transactions as the data storage. Vote coin provides technical solution for knowledge based democracy. Person can delegate his voting power to other person. Delegation may be partial, one can give 50 points to person A and 100 points to person B. Delegation can be also content specific. If person trusts person A in economy matters he can delegate his full voting power to him, while he can delegate full voting power in matter of IT tech to person B. Casting of the votes can be changed, and the last vote in the voting round is valid. Voting can be used as 1 vote = 1 vote, or 1 person = 1 vote. Quadratic voting can be applied if DAO requires it. Voting can be used by DAOs, corporations, governments or individuals. Audit services may be purchased."])},
    "q2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I have my custom ASA as basis for 1 vote = 1 ASA token?"])},
    "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes. This is the main use case. DAO usually has distributed their tokens to the owners. DAO asks a question which costs 0.001 algo. DAO members vote or delegate their voting power to other members. After voting is finished, auditors publish the results. At the end, DAO calculates results or takes the results of the auditors, and publish the final vote result. From this message the action may be triggered, for example the github PR merge."])},
    "q3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is delegation pernament?"])},
    "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, the delegation within scope of the asset is pernament until it has been changed. When person trusts the politician, he delegates him his voting power. Person can change his delegation preferences any time. The decision point for vote calculation is the final block of the voting. If person A votes and person B does not vote, if person B delegate his voting power to person A 1 block before end of the voting, his voting power for this voting session will be transfered."])},
    "q6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do you call it knowledge based pure democracy system?"])},
    "a6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggregation of wisdom is ensured by categorized delegation. Every question is context based. Some questions requires IT knowledge, some questions requires knowledge of laws. If person A trusts person B and C in IT matters, he can delegate for example 20 IT points to person B, 30 IT points to person C, and 20 Legal points to person D. If there is question regarding the IT stuff, for example github PR merge request and person A does not vote, he delegates his voting power to 20/50 to person B, and 30/50 to person C. Person B can delegate his voting power to other persons he knows they do good decisions, for example 100 IT points to person C. If only person C votes, he receives the voting power from person A, B, and himself. Delegation of power does not mean that person cannot vote. It is pure democratic system which means that if person votes, he always represents his voting power, and his voting power is not being used by anyone else."])},
    "q7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I help the project?"])},
    "a7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please spread the word on youtube, twitter or other means. We have active engagement program where you can receive Vote Coin tokens."])},
    "a7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are developer, please take a look at the engagement program. If you create open source solution based on the vote coin standard, you can receive vote coin token rewards."])},
    "a7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you represent a DAO or corporation, please support us by purchasing Auditing services. We will calculate the results of voting for you so that you can be ensured you have the correct results."])},
    "q8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much will it cost me?"])},
    "a8_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are DAO, it will cost you 0.002 algo per question - you ask a question through blockchain, and set final results."])},
    "a8_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your DAO members will pay pay 0.001 algo to cast votes. They can delegate their voting power for 0.001 to other persons so that they do not have to vote in each question, but rather delegate their voting power to trusted person."])},
    "a8_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If DAO wants to manage their own list of allowed person, in one transaction it can add approx 15 accounts to the trusted list."])},
    "a8_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are single person who wants to ask a question, just place a question to the algo token."])}
  }
}